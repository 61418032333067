import * as React from "react"
import Layout from "../components/layout"

const NotFound = () => (
  <Layout>
    <h1>404 - 页面未找到</h1>
    <p>哎呀，你要找的页面消失了</p>
  </Layout>
)

export default NotFound
